import React, { useEffect, useState, useRef } from 'react';
import {
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Radio,
  Row,
  Select,
  Tabs,
  Input,
  Alert
} from 'antd';
import Boton from '../Components/Atoms/Boton';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import clienteAxios from '../Utils/clienteAxios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseCircleOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import TableRouteComprasProceso from '../Components/Organisms/TableRouteComprasProceso';
import TableRouteComprasFinish from '../Components/Organisms/TableRouteComprasFinish';
dayjs.extend(customParseFormat);
import decodeToken from '../Utils/decodeToken';
import es_ES from 'antd/locale/es_ES';

const RoutesCompras = () => {
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [valueTable, setValueTable] = useState('1');
  const [filterDestino, setFilterDestino] = useState('');
  const [filterTipoEntrega, setFilterTipoEntrega] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [proceso, setProceso] = useState([]);

  const [allProductsDataPendientes, setAllProductsDataPendientes] = useState([]);
  const [globalDatePendientes, setGlobalDatePendientes] = useState(null);
  const [globalTipoEntregaPendentes, setGlobalTipoEntregaPendientes] = useState(null);
  const [globalEstadoEntregaPendinetes, setGlobalEstadoEntregaPendientes] = useState(null);
  const [globalObservationPendientes, setGlobalObservationPendientes] = useState('');

  const [allProductsPendientes, setAllProductsPendientes] = useState(false);
  const [excludedRowsPendientes, setExcludedRowsPendientes] = useState([]);
  const [selectedRowsPendientes, setSelectedRowsPendientes] = useState([]);

  const [finalizado, setFinalizado] = useState([]);
  const [allProductsDataFinalizado, setAllProductsDataFinalizado] = useState([]);
  const [globalDateCompleta, setGlobalDateCompleta] = useState(null);
  const [globalTipoEntregaCompleta, setGlobalTipoEntregaCompleta] = useState(null);
  const [globalEstadoEntregaCompleta, setGlobalEstadoEntregaCompleta] = useState(null);
  const [globalObservationCompleta, setGlobalObservationCompleta] = useState('');

  const [allProductsCompleta, setAllProductsCompleta] = useState(false);
  const [excludedRowsCompleta, setExcludedRowsCompleta] = useState([]);
  const [selectedRowsCompleta, setSelectedRowsCompleta] = useState([]);
  const [mostarObservacion, setMostrarObservacion] = useState();
  let decodedToken = decodeToken(localStorage.getItem('token'));
  let userArea = decodedToken?.user?.display_name_area;
  let userRole = decodedToken?.role?.name;

  const disableequipo_logistica = userArea === 'logistica' ? true : false;

  const tableRefFinish = useRef(null);
  const tableRefProceso = useRef(null);
  const [locale] = useState(es_ES);
  const { TextArea } = Input;

  useEffect(() => {
    getRoutes(filterDate, filterTipoEntrega, filterDestino);
  }, [filterDate, filterTipoEntrega, filterDestino]);

  const getRoutes = (filterDate, filterTipoEntrega, filterDestino) => {
    {
      filterDate === 'Invalid Date' ? (filterDate = ' ') : null;
    }
    setLoading(true);

    clienteAxios
      .get('rutas-de-entrega/Compras-Pendientes', {
        params: { date: filterDate, type: filterTipoEntrega, destiny: filterDestino }
      })
      .then((response) => {
        let dataPendientes = response?.data?.Rutas_Pendientes;
        setProceso(dataPendientes);
        setAllProductsDataPendientes(dataPendientes);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });

    clienteAxios
      .get('rutas-de-entrega/Compras-Completa', {
        params: { date: filterDate, type: filterTipoEntrega, destiny: filterDestino }
      })
      .then((response) => {
        let dataFinalizado = response?.data?.Rutas_Completas;
        setFinalizado(dataFinalizado);
        setAllProductsDataFinalizado(dataFinalizado);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      valueTable === '1'
        ? (document.querySelector('.tablaComprasProceso .ant-table-content table ').id =
            'table-to-xls-pendientes')
        : (document.querySelector('.tablaComprasFinish .ant-table-content table').id =
            'table-to-xls-finish');
    }, 1000);
    return () => clearTimeout(timer);
  }, [valueTable]);

  const handleSelectAllChangePendientes = (e) => {
    setMostrarObservacion('');
    if (e.target.checked) {
      const allKeysPendientes = proceso.map((item, index) => index);
      setSelectedRowsPendientes(allKeysPendientes);
      if (globalDatePendientes) {
        const updatedDataPendientes = proceso.map((item) => ({
          ...item,
          date_of_delivery: globalDatePendientes
        }));
        setProceso(updatedDataPendientes);
      }
      if (globalTipoEntregaPendentes) {
        const updatedDataPendientes = proceso.map((item) => ({
          ...item,
          type: globalTipoEntregaPendentes
        }));
        setProceso(updatedDataPendientes);
      }
      if (globalEstadoEntregaPendinetes) {
        const updatedDataPendientes = proceso.map((item) => ({
          ...item,
          status_delivery: globalEstadoEntregaPendinetes
        }));
        setProceso(updatedDataPendientes);
      }
      if (globalObservationPendientes) {
        const updatedDataPendientes = proceso.map((item) => ({
          ...item,
          observation: globalObservationPendientes
        }));
        setProceso(updatedDataPendientes);
      }
    } else {
      setSelectedRowsPendientes([]);
    }
    setAllProductsPendientes(e.target.checked);
    setAllProductsCompleta(false);
    setSelectedRowsCompleta([]);
  };

  const handleSelectAllChangeCompleta = (e) => {
    setMostrarObservacion('');
    if (e.target.checked) {
      const allKeysCompleta = finalizado.map((item, index) => index);
      setSelectedRowsCompleta(allKeysCompleta);
      if (globalDateCompleta) {
        const updatedDataCompleta = finalizado.map((item) => ({
          ...item,
          date_of_delivery: globalDateCompleta
        }));
        setFinalizado(updatedDataCompleta);
      }
      if (globalTipoEntregaCompleta) {
        const updatedDataCompleta = finalizado.map((item) => ({
          ...item,
          type: globalTipoEntregaCompleta
        }));
        setFinalizado(updatedDataCompleta);
      }
      if (globalEstadoEntregaCompleta) {
        const updatedDataCompleta = finalizado.map((item) => ({
          ...item,
          status_delivery: globalEstadoEntregaCompleta
        }));
        setFinalizado(updatedDataCompleta);
      }
      if (globalObservationCompleta) {
        const updatedDataCompleta = finalizado.map((item) => ({
          ...item,
          observation: globalObservationCompleta
        }));
        setFinalizado(updatedDataCompleta);
      }
    } else {
      setSelectedRowsCompleta([]);
    }
    setAllProductsCompleta(e.target.checked);
    setAllProductsPendientes(false);
    setSelectedRowsPendientes([]);
  };

  const onChangeCancelEdit = () => {
    setEdit(false);
    setAllProductsPendientes(false);
    setSelectedRowsPendientes([]);
    setExcludedRowsPendientes([]);
    setAllProductsCompleta(false);
    setSelectedRowsCompleta([]);
    setExcludedRowsCompleta([]);
  };

  const onChangeFilterData = (date, dateString) => {
    setFilterDate(dayjs(dateString).format('YYYY/MM/DD'));
  };

  const onChangeFilterTipoEntrega = (e) => {
    setFilterTipoEntrega(e.target.value);
  };

  const onChangeFilterDestino = (e) => {
    setFilterDestino(e.target.value);
  };

  let arrayEdit = [];
  let arrayEditCompleta = [];

  for (let i = 0; i < allProductsDataPendientes?.length; i++) {
    if (
      allProductsDataPendientes[i]?.date_of_delivery !== proceso[i]?.date_of_delivery ||
      allProductsDataPendientes[i]?.shipping_type !== proceso[i]?.shipping_type ||
      allProductsDataPendientes[i]?.status_delivery !== proceso[i]?.status_delivery ||
      allProductsDataPendientes[i]?.type !== proceso[i]?.type ||
      allProductsDataPendientes[i]?.observation !== proceso[i]?.observation
    ) {
      arrayEdit.push(proceso[i]);
    }
  }

  for (let i = 0; i < allProductsDataFinalizado?.length; i++) {
    if (
      allProductsDataFinalizado[i]?.date_of_delivery !== finalizado[i]?.date_of_delivery ||
      allProductsDataFinalizado[i]?.shipping_type !== finalizado[i]?.shipping_type ||
      allProductsDataFinalizado[i]?.status_delivery !== finalizado[i]?.status_delivery ||
      allProductsDataFinalizado[i]?.type !== finalizado[i]?.type ||
      allProductsDataFinalizado[i]?.observation !== finalizado[i]?.observation
    ) {
      arrayEditCompleta.push(finalizado[i]);
    }
  }

  const filteredArray = arrayEdit.filter((item, index) => !excludedRowsPendientes.includes(index));
  const filteredArrayCompleta = arrayEditCompleta.filter(
    (item, index) => !excludedRowsCompleta.includes(index)
  );

  const handleEditDataTable = () => {
    Swal.fire({
      title: '¿Estas seguro de actualizar las rutas?',
      icon: 'question',
      confirmButtonText: 'Si, actualizar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios
          .post(
            'rutas-de-entrega/Editar/Compras-Pendientes',
            valueTable === '1' ? filteredArray : filteredArrayCompleta
          )
          .then((response) => {
            console.log('response', response);
            Swal.fire({
              title: 'Rutas actualizadas',
              icon: 'success'
            }).then(() => {
              setEdit(false);
              setAllProductsPendientes(false);
              setSelectedRowsPendientes([]);
              setExcludedRowsPendientes([]);
              setAllProductsCompleta(false);
              setSelectedRowsCompleta([]);
              setExcludedRowsCompleta([]);
              getRoutes(filterDate, filterTipoEntrega, filterDestino);
            });
          })
          .catch((error) => {
            console.error(error);
            Swal.fire({
              title: 'Hubo un error al actualizar las rutas',
              icon: 'error'
            });
          });
      }
    });
  };

  const handleGlobalTipoEntregaChangePendientes = (value) => {
    setGlobalTipoEntregaPendientes(value);
    if (allProductsPendientes) {
      const updatedDataPendientes = proceso.map((item) => {
        if (!excludedRowsPendientes.includes(item.key)) {
          return {
            ...item,
            type: value
          };
        }
        return item;
      });
      setProceso(updatedDataPendientes);
    }
  };

  const handleGlobalTipoEntregaChangeCompleta = (value) => {
    setGlobalTipoEntregaCompleta(value);
    if (allProductsCompleta) {
      const updatedDataCompleta = finalizado.map((item) => {
        if (!excludedRowsCompleta.includes(item.key)) {
          return {
            ...item,
            type: value
          };
        }
        return item;
      });
      setFinalizado(updatedDataCompleta);
    }
  };

  const handleGlobalDateChangePendientes = (date, dateString) => {
    setGlobalDatePendientes(dateString);
    if (allProductsPendientes) {
      const updatedDataPendientes = proceso.map((item, index) => {
        if (!excludedRowsPendientes.includes(index)) {
          return {
            ...item,
            date_of_delivery: dateString
          };
        }
        return item;
      });
      setProceso(updatedDataPendientes);
    }
  };

  const handleGlobalDateChangeCompleta = (date, dateString) => {
    setGlobalDateCompleta(dateString);
    if (allProductsCompleta) {
      const updatedDataCompleta = finalizado.map((item, index) => {
        if (!excludedRowsCompleta.includes(index)) {
          return {
            ...item,
            date_of_delivery: dateString
          };
        }
        return item;
      });
      setFinalizado(updatedDataCompleta);
    }
  };

  const handleGlobalEstadoEntregaChangePendientes = (value) => {
    setGlobalEstadoEntregaPendientes(value);
    if (allProductsPendientes) {
      const updatedDataPendientes = proceso.map((item, index) => {
        if (!excludedRowsPendientes.includes(index)) {
          return {
            ...item,
            status_delivery: value
          };
        }
        return item;
      });
      setProceso(updatedDataPendientes);
      setMostrarObservacion(value);
    }
  };

  const handleGlobalEstadoEntregaChangeCompleta = (value) => {
    setGlobalEstadoEntregaCompleta(value);
    if (allProductsCompleta) {
      const updatedDataCompleta = finalizado.map((item, index) => {
        if (!excludedRowsCompleta.includes(index)) {
          return {
            ...item,
            status_delivery: value
          };
        }
        return item;
      });
      setFinalizado(updatedDataCompleta);
      setMostrarObservacion(value);
    }
  };

  const handleGlobalObservacionChangePendientes = (e) => {
    setGlobalObservationPendientes(e.target.value);
    if (allProductsPendientes) {
      const updatedDataPendientes = proceso.map((item, index) => {
        if (!excludedRowsPendientes.includes(index)) {
          return {
            ...item,
            observation: e.target.value
          };
        }
        return item;
      });
      setProceso(updatedDataPendientes);
    }
  };

  const handleGlobalObservacionChangeCompleta = (e) => {
    setGlobalObservationCompleta(e.target.value);
    if (allProductsCompleta) {
      const updatedDataCompleta = finalizado.map((item, index) => {
        if (!excludedRowsCompleta.includes(index)) {
          return {
            ...item,
            observation: e.target.value
          };
        }
        return item;
      });
      setFinalizado(updatedDataCompleta);
    }
  };

  const option_status_delivery = [
    { value: 'Completo', label: 'Completo' },
    { value: 'Pendiente', label: 'Pendiente' },
    { value: 'Reprogramado', label: 'Reprogramado' }
  ];
  const filterOptionStatus_delivery =
    userArea === 'sgcmc' || userRole === 'Super_Admin' || decodedToken?.sub === 280
      ? option_status_delivery
      : userArea === 'logistica'
      ? option_status_delivery.filter(
          (item) => item.value === 'Completo' || item.value === 'Reprogramado'
        )
      : userArea === 'compras' || userArea === 'mesa_de_control'
      ? option_status_delivery.filter(
          (item) => item.value === 'Pendiente' || item.value === 'Reprogramado'
        )
      : null;

  return (
    <>
      <h1 className="mb-2">
        {decodedToken?.role?.name === 'compras'
          ? 'rutas compras'
          : decodedToken?.role?.name === 'equipo_mesa_control'
          ? 'rutas mesa de control'
          : 'rutas de entrega'}
      </h1>
      <div className="flex justify-content-end mb-2">
        {edit === false ? (
          <>
            <ReactHtmlTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button"
              table={valueTable === '1' ? 'table-to-xls-pendientes' : 'table-to-xls-finish'}
              filename={valueTable === '1' ? 'Rutas En Proceso' : 'Rutas Completadas'}
              sheet={valueTable === '1' ? 'Rutas En Proceso' : 'Rutas Completadas'}
              buttonText="Exportar en Excel"
            />

            {proceso?.length > 0 || finalizado?.length > 0 ? (
              <Boton type="secondary" onClick={() => setEdit(true)} className="ml-4">
                Editar Ruta
              </Boton>
            ) : null}
          </>
        ) : (
          <>
            <Boton type="primary" onClick={handleEditDataTable} className="mr-3">
              Guardar Cambios
            </Boton>
            <Boton danger onClick={onChangeCancelEdit}>
              Cancelar Edición
            </Boton>
          </>
        )}
      </div>

      {edit === true || edit === true ? null : (
        <>
          <h3>Filtrar por:</h3>
          <Row>
            <Col
              xs={{ span: 24 }}
              lg={userRole === 'Super_Admin' || userArea === 'logistica' ? { span: 11 } : 10}
              className="mt-2">
              <span className="mt-1 mr-3">Destino:</span>
              <Radio.Group
                buttonStyle="solid"
                value={filterDestino}
                onChange={onChangeFilterDestino}>
                <Radio.Button value="Almacen PL">Almacen PL</Radio.Button>
                <Radio.Button value="Maquila">Maquila</Radio.Button>
                <Radio.Button value="Almacen PM">Almacen PM</Radio.Button>
                {['equipo_mesa_control', 'logistica', 'administrator', 'sgcmc'].includes(
                  userArea
                ) || decodedToken?.sub === 280 ? (
                  <Radio.Button value="Cliente">Cliente</Radio.Button>
                ) : null}
              </Radio.Group>
              {filterDestino !== '' ? (
                <Boton
                  className="ml-2"
                  style={{
                    border: 'none',
                    height: '20px',
                    boxShadow: 'none',
                    padding: '0px ',
                    color: 'var(--secondary-color-red)'
                  }}
                  onClick={() => setFilterDestino('')}>
                  <CloseCircleOutlined />
                </Boton>
              ) : null}
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 7 }} className="mt-2">
              <span className="mt-1 mr-3">Tipo de entrega:</span>
              <Radio.Group
                buttonStyle="solid"
                value={filterTipoEntrega}
                onChange={onChangeFilterTipoEntrega}>
                <Radio.Button value="Parcial">Parcial</Radio.Button>
                <Radio.Button value="Total">Total</Radio.Button>
              </Radio.Group>
              {filterTipoEntrega !== '' ? (
                <Boton
                  className="ml-2"
                  style={{
                    border: 'none',
                    height: '20px',
                    boxShadow: 'none',
                    padding: '0px ',
                    color: 'var(--secondary-color-red)'
                  }}
                  onClick={() => setFilterTipoEntrega('')}>
                  <CloseCircleOutlined />
                </Boton>
              ) : null}
            </Col>

            <Col
              xs={{ span: 24 }}
              lg={
                decodedToken?.role?.name === 'administrator' ||
                userArea === 'logistica' ||
                userRole === 'Super_Admin'
                  ? { span: 6 }
                  : { span: 6, offset: 1 }
              }
              className="mt-2">
              <span className="mt-1 mr-2">Fecha de entrega:</span>
              <ConfigProvider locale={locale}>
                <DatePicker onChange={onChangeFilterData} />
              </ConfigProvider>
            </Col>
          </Row>
        </>
      )}

      {edit === true ? (
        <>
          {valueTable === '1' || (valueTable === '2' && userArea !== 'logistica') ? (
            <Row
              className={
                valueTable === '1' && allProductsPendientes === true
                  ? null
                  : valueTable === '2' && allProductsCompleta === true
                  ? null
                  : 'mt-2'
              }>
              <Col span={24} className="mt-2">
                <strong className="mt-1 mr-2">Seleccionar todos los productos:</strong>
                <Checkbox
                  onChange={
                    valueTable === '1'
                      ? handleSelectAllChangePendientes
                      : valueTable === '2'
                      ? handleSelectAllChangeCompleta
                      : null
                  }
                  checked={
                    valueTable === '1'
                      ? allProductsPendientes
                      : valueTable === '2'
                      ? allProductsCompleta
                      : null
                  }
                />
              </Col>
            </Row>
          ) : null}

          {(valueTable === '1' && allProductsPendientes === true) ||
          (valueTable === '2' && allProductsCompleta === true) ? (
            <>
              <Alert
                type="warning"
                showIcon
                message="La selección que hagas se aplicará a todos los productos seleccionados."
                className="mt-2"
              />
              <Row className="mt-2">
                <>
                  <Col xs={{ span: 24 }} lg={{ span: 6 }} className="mt-2">
                    <span className="mt-1 mr-2">Fecha de entrega:</span>
                    <ConfigProvider locale={locale}>
                      <DatePicker
                        onChange={
                          allProductsPendientes === true
                            ? handleGlobalDateChangePendientes
                            : allProductsCompleta === true
                            ? handleGlobalDateChangeCompleta
                            : null
                        }
                        disabledDate={(current) => current && current < dayjs().startOf('day')}
                      />
                    </ConfigProvider>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 6, offset: 4 }} className="mt-2">
                    <span className="mt-1 mr-2">Tipo de entrega:</span>
                    <Select
                      style={{ width: 150 }}
                      disabled={disableequipo_logistica}
                      options={[
                        { value: 'Parcial', label: 'Parcial' },
                        { value: 'Total', label: 'Total' }
                      ]}
                      onChange={
                        allProductsPendientes === true
                          ? handleGlobalTipoEntregaChangePendientes
                          : allProductsCompleta === true
                          ? handleGlobalTipoEntregaChangeCompleta
                          : null
                      }
                    />
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }} className="mt-2">
                    <span className="mt-1 mr-2">Estado de entrega:</span>
                    <Select
                      style={{ width: 150 }}
                      options={filterOptionStatus_delivery}
                      onChange={
                        allProductsPendientes === true
                          ? handleGlobalEstadoEntregaChangePendientes
                          : allProductsCompleta === true
                          ? handleGlobalEstadoEntregaChangeCompleta
                          : null
                      }
                    />
                  </Col>
                  {mostarObservacion === 'Reprogramado' ? (
                    <Col xs={{ span: 24 }} lg={{ span: 6 }} className="mt-2">
                      <span className="mt-1 mr-2">Observacion:</span>
                      <TextArea
                        rows={2}
                        onChange={
                          allProductsPendientes === true
                            ? handleGlobalObservacionChangePendientes
                            : allProductsCompleta === true
                            ? handleGlobalObservacionChangeCompleta
                            : null
                        }
                      />
                    </Col>
                  ) : null}
                </>
              </Row>
            </>
          ) : null}
        </>
      ) : null}

      <Tabs
        className="mt-3"
        defaultActiveKey={valueTable}
        onChange={(e) => {
          setValueTable(e);
        }}
        items={[
          {
            key: '1',
            label: `En proceso`,
            children: (
              <TableRouteComprasProceso
                tableRefProceso={tableRefProceso}
                proceso={proceso}
                setProceso={setProceso}
                data={allProductsDataPendientes}
                loading={loading}
                edit={edit}
                allProductsPendientes={allProductsPendientes}
                excludedRowsPendientes={excludedRowsPendientes}
                setExcludedRowsPendientes={setExcludedRowsPendientes}
                selectedRowsPendientes={selectedRowsPendientes}
                setSelectedRowsPendientes={setSelectedRowsPendientes}
                setSelectedRowsCompleta={setSelectedRowsCompleta}></TableRouteComprasProceso>
            )
          },
          {
            key: '2',
            label: `Completas`,
            children: (
              <TableRouteComprasFinish
                tableRefFinish={tableRefFinish}
                finalizado={finalizado}
                setFinalizado={setFinalizado}
                data={allProductsDataFinalizado}
                loading={loading}
                edit={edit}
                allProductsCompleta={allProductsCompleta}
                excludedRowsCompleta={excludedRowsCompleta}
                setExcludedRowsCompleta={setExcludedRowsCompleta}
                selectedRowsCompleta={selectedRowsCompleta}
                setSelectedRowsCompleta={setSelectedRowsCompleta}
                setSelectedRowsPendientes={setSelectedRowsPendientes}></TableRouteComprasFinish>
            )
          }
        ]}></Tabs>
    </>
  );
};

export default RoutesCompras;
