import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Boton from '../Components/Atoms/Boton';
import { ReconciliationOutlined } from '@ant-design/icons';
import clienteAxios from '../Utils/clienteAxios';
import decodeToken from '../Utils/decodeToken';
import { Tabs, Alert, message } from 'antd';
import Swal from 'sweetalert2';
import FilterRequest from '../Components/Organisms/FilterRequest';

const SolicitudesGasto = () => {
  const [requestsManager, setRequestsManager] = useState([]);
  const [adminRequests, setAdminRequests] = useState([]);
  const [myRequests, setMyRequests] = useState([]);
  const [shopping, setShopping] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingAdmin, setLoadingAdmin] = useState(true);
  let decodedToken = decodeToken(localStorage.getItem('token'));
  const userRole = decodedToken?.role?.name;
  let startOfMonth;
  let endOfMonth;
  const [fechainial, setFechainial] = useState(startOfMonth);
  const [fechafinal, setFechafinal] = useState(endOfMonth);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [dataFilterMonthManager, setDataFilterMonthManager] = useState([]);
  const [dataFilterMonthAdmin, setDataFilterMonthAdmin] = useState([]);
  const [dataFilterMonthShopping, setDataFilterMonthShoping] = useState([]);
  const [dataFilterMonthMyOrder, setDataFilterMonthMyOrder] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [filterActiveMyOrder, setFilterActiveMyOrder] = useState(false);

  const allowedRoles = [
    'equipo_administracion',
    'equipo_almacen',
    'equipo_calidad',
    'equipo_compras',
    'equipo_direccion',
    'equipo_diseno_operativo',
    'equipo_importaciones',
    'equipo_logistica',
    'equipo_mesa_control',
    'equipo_produccion',
    'equipo_recursos_humanos',
    'equipo_ti',
    'equipo_ventas_bh',
    'equipo_ventas_pl',
    'sin_definir',
    'Super_Admin',
    'marketing_pl'
  ];

  useEffect(() => {
    if (userRole === 'manager') {
      clienteAxios
        .get(`/caja-chica/solicitudes-de-compra/por-departamento/ver`)
        .then((response) => {
          let data = response.data.spents;
          data.reverse();
          setRequestsManager(data);
          setLoading(false);
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: error.response?.data?.message,
            confirmButtonText: 'Aceptar'
          });
        });
    }
  }, [userRole]);

  useEffect(() => {
    clienteAxios
      .get(`/caja-chica/mis-ordenes`)
      .then((response) => {
        let data = response.data.spents;
        data.reverse();
        setMyRequests(data);
        setLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: error.response?.data?.message,
          confirmButtonText: 'Aceptar'
        });
      });
  }, []);

  useEffect(() => {
    if (
      userRole === 'caja_chica' ||
      userRole === 'adquisiciones_pl' ||
      userRole === 'adquisiciones_bh'
    ) {
      clienteAxios
        .get(`/caja-chica/ordenes-comprador`)
        .then((response) => {
          let data = response.data.spents;
          data.reverse();
          setShopping(data);
          setLoading(false);
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: error.response?.data?.message,
            confirmButtonText: 'Aceptar'
          });
        });
    }
  }, [userRole]);

  useEffect(() => {
    if (userRole === 'administrator' || userRole === 'Super_Admin') {
      clienteAxios
        .get(`/caja-chica/administrador/solicitudes-de-compra/ver`)
        .then((response) => {
          let data = response.data.spents;
          data.reverse();
          setAdminRequests(data);
          setLoadingAdmin(false);
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: error.response?.data?.message,
            confirmButtonText: 'Aceptar'
          });
        });
    }
  }, [userRole]);

  const handleDatingManager = () => {
    message.loading('Filtrando solicitudes...', 0);
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/caja-chica/solicitudes-de-compra/por-departamento-y-fecha`, {
        start: fechainial,
        end: fechafinal
      })
      .then((response) => {
        let data = response.data.spents;
        data.reverse();
        setIsButtonDisabled(false);
        message.destroy();
        setDataFilterMonthManager(data);
        message.success('Solicitudes filtradas correctamente');
        setFilterActive(true);
      })
      .catch((error) => {
        message.destroy();
        Swal.fire({
          icon: 'error',
          title: error.response?.data?.message,
          confirmButtonText: 'Aceptar'
        });
        setIsButtonDisabled(false);
      });
  };

  const handleDatingAdmin = () => {
    message.loading('Filtrando solicitudes...', 0);
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/caja-chica/administrador/solicitudes-de-compra/ver-por-fecha`, {
        start: fechainial,
        end: fechafinal
      })
      .then((response) => {
        let data = response.data.spents;
        data.reverse();
        setIsButtonDisabled(false);
        message.destroy();
        setDataFilterMonthAdmin(data);
        message.success('Solicitudes filtradas correctamente');
        setFilterActive(true);
      })
      .catch((error) => {
        message.destroy();
        Swal.fire({
          icon: 'error',
          title: error.response?.data?.message,
          confirmButtonText: 'Aceptar'
        });
        setIsButtonDisabled(false);
      });
  };

  const handleDatingShopping = () => {
    message.loading('Filtrando solicitudes...', 0);
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/caja-chica/ordenes-comprador-por-fecha`, {
        start: fechainial,
        end: fechafinal
      })
      .then((response) => {
        let data = response.data.spents;
        data.reverse();
        setIsButtonDisabled(false);
        message.destroy();
        setDataFilterMonthShoping(data);
        message.success('Solicitudes filtradas correctamente');
        setFilterActive(true);
      })
      .catch((error) => {
        message.destroy();
        Swal.fire({
          icon: 'error',
          title: error.response?.data?.message,
          confirmButtonText: 'Aceptar'
        });
        setIsButtonDisabled(false);
      });
  };

  const handleDatingMyOrder = () => {
    message.loading('Filtrando solicitudes...', 0);
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/caja-chica/mis-ordenes-por-fecha`, {
        start: fechainial,
        end: fechafinal
      })
      .then((response) => {
        let data = response.data.spents;
        data.reverse();
        setIsButtonDisabled(false);
        message.destroy();
        setDataFilterMonthMyOrder(data);
        message.success('Solicitudes filtradas correctamente');
        setFilterActiveMyOrder(true);
      })
      .catch((error) => {
        message.destroy();
        Swal.fire({
          icon: 'error',
          title: error.response?.data?.message,
          confirmButtonText: 'Aceptar'
        });
        setIsButtonDisabled(false);
      });
  };

  return (
    <>
      <div className="title-content-between">
        <h1 className="mb-2 mr-5">Solicitudes de gasto</h1>
        {userRole === 'administrator' ||
        userRole === 'Super_Admin' ||
        userRole === 'caja_chica' ||
        userRole === 'adquisiciones_pl' ||
        userRole === 'adquisiciones_bh' ? (
          <Link to="/reporte-general">
            <Boton className="mb-2" type="secondary" icon={<ReconciliationOutlined />}>
              Reporte General
            </Boton>
          </Link>
        ) : null}
        <Link to="/nueva-solicitud">
          <Boton type="primary">Nueva Solicitud</Boton>
        </Link>
      </div>
      {userRole === 'administrator' || userRole === 'Super_Admin' ? (
        <>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: `Solicitudes Por Aprobar`,
                children: (
                  <>
                    <Alert
                      message="En este apartado se muestran todas las 'SOLICITUDES DE GASTO', sin importar el estado en el que se encuentren."
                      type="info"
                      showIcon
                      closable
                    />
                    <FilterRequest
                      startOfMonth={startOfMonth}
                      endOfMonth={endOfMonth}
                      setFechainial={setFechainial}
                      setFechafinal={setFechafinal}
                      handleDating={handleDatingAdmin}
                      isButton={isButtonDisabled}
                      setFilterActive={setFilterActive}
                      setDataFilterMonth={setDataFilterMonthAdmin}
                      filterActive={filterActive}
                      dataFilterMonth={dataFilterMonthAdmin}
                      dataRequests={adminRequests}
                      loading={loadingAdmin}
                    />
                  </>
                )
              },
              {
                key: '2',
                label: `Mis Solicitudes`,
                children: (
                  <>
                    <FilterRequest
                      startOfMonth={startOfMonth}
                      endOfMonth={endOfMonth}
                      setFechainial={setFechainial}
                      setFechafinal={setFechafinal}
                      handleDating={handleDatingMyOrder}
                      isButton={isButtonDisabled}
                      setFilterActive={setFilterActiveMyOrder}
                      setDataFilterMonth={setDataFilterMonthMyOrder}
                      filterActive={filterActiveMyOrder}
                      dataFilterMonth={dataFilterMonthMyOrder}
                      dataRequests={myRequests}
                      loading={loading}
                    />
                  </>
                )
              }
            ]}
          />
        </>
      ) : allowedRoles.includes(userRole) ? (
        <>
          <FilterRequest
            startOfMonth={startOfMonth}
            endOfMonth={endOfMonth}
            setFechainial={setFechainial}
            setFechafinal={setFechafinal}
            handleDating={handleDatingMyOrder}
            isButton={isButtonDisabled}
            setFilterActive={setFilterActiveMyOrder}
            setDataFilterMonth={setDataFilterMonthMyOrder}
            filterActive={filterActiveMyOrder}
            dataFilterMonth={dataFilterMonthMyOrder}
            dataRequests={myRequests}
            loading={loading}
          />
        </>
      ) : userRole === 'caja_chica' ||
        userRole === 'adquisiciones_bh' ||
        userRole === 'adquisiciones_pl' ? (
        <>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: `Solicitudes De Adquisición`,
                children: (
                  <>
                    <Alert
                      message="En este apartado solamente se mostrarán las  'SOLICITUDES DE GASTO'  que ya fueron aprobadas por el Administrador para proceder a su compra."
                      type="info"
                      showIcon
                      closable
                    />
                    <FilterRequest
                      startOfMonth={startOfMonth}
                      endOfMonth={endOfMonth}
                      setFechainial={setFechainial}
                      setFechafinal={setFechafinal}
                      handleDating={handleDatingShopping}
                      isButton={isButtonDisabled}
                      setFilterActive={setFilterActive}
                      setDataFilterMonth={setDataFilterMonthShoping}
                      filterActive={filterActive}
                      dataFilterMonth={dataFilterMonthShopping}
                      dataRequests={shopping}
                      loading={loading}
                    />
                  </>
                )
              },
              {
                key: '2',
                label: `Mis Solicitudes`,
                children: (
                  <>
                    <FilterRequest
                      startOfMonth={startOfMonth}
                      endOfMonth={endOfMonth}
                      setFechainial={setFechainial}
                      setFechafinal={setFechafinal}
                      handleDating={handleDatingMyOrder}
                      isButton={isButtonDisabled}
                      setFilterActive={setFilterActiveMyOrder}
                      setDataFilterMonth={setDataFilterMonthMyOrder}
                      filterActive={filterActiveMyOrder}
                      dataFilterMonth={dataFilterMonthMyOrder}
                      dataRequests={myRequests}
                      loading={loading}
                    />
                  </>
                )
              }
            ]}
          />
        </>
      ) : userRole === 'manager' ? (
        <>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: `Solicitudes Por Aprobar`,
                children: (
                  <>
                    <Alert
                      message="En este apartado solamente se mostrarán las 'SOLICITUDES DE GASTO' que tienes de tú área."
                      type="info"
                      showIcon
                      closable
                    />
                    <FilterRequest
                      startOfMonth={startOfMonth}
                      endOfMonth={endOfMonth}
                      setFechainial={setFechainial}
                      setFechafinal={setFechafinal}
                      handleDating={handleDatingManager}
                      isButton={isButtonDisabled}
                      setFilterActive={setFilterActive}
                      setDataFilterMonth={setDataFilterMonthManager}
                      filterActive={filterActive}
                      dataFilterMonth={dataFilterMonthManager}
                      dataRequests={requestsManager}
                      loading={loading}
                    />
                  </>
                )
              },
              {
                key: '2',
                label: `Mis Solicitudes`,
                children: (
                  <>
                    <FilterRequest
                      startOfMonth={startOfMonth}
                      endOfMonth={endOfMonth}
                      setFechainial={setFechainial}
                      setFechafinal={setFechafinal}
                      handleDating={handleDatingMyOrder}
                      isButton={isButtonDisabled}
                      setFilterActive={setFilterActiveMyOrder}
                      setDataFilterMonth={setDataFilterMonthMyOrder}
                      filterActive={filterActiveMyOrder}
                      dataFilterMonth={dataFilterMonthMyOrder}
                      dataRequests={myRequests}
                      loading={loading}
                    />
                  </>
                )
              }
            ]}
          />
        </>
      ) : null}
    </>
  );
};
export default SolicitudesGasto;
