import React from 'react';
import { DatePicker, message } from 'antd';
import { SearchOutlined, ClearOutlined } from '@ant-design/icons';
import es from 'antd/lib/date-picker/locale/es_ES';
import Boton from '../Atoms/Boton';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import TablaSolicitudesCompra from '../../Components/Organisms/TablaSolicitudesCompra';

export default function FilterRequest({
  startOfMonth,
  endOfMonth,
  setFechainial,
  setFechafinal,
  handleDating,
  isButton,
  setFilterActive,
  setDataFilterMonth,
  filterActive,
  dataFilterMonth,
  dataRequests,
  loading
}) {
  startOfMonth = dayjs().startOf('month');
  endOfMonth = dayjs().endOf('month');
  const { RangePicker } = DatePicker;

  return (
    <>
      <p className="mt-3">Solicitudes mostradas de: </p>

      <div className="flex-row align-items-center mt-3">
        <RangePicker
          format="DD-MM-YYYY"
          placeholder={['Fecha de inicio', 'Fecha de fin']}
          locale={es}
          defaultValue={[startOfMonth, endOfMonth]}
          onChange={(dates) => {
            if (dates) {
              setFechainial(dates[0].format('YYYY-MM-DD'));
              setFechafinal(dates[1].format('YYYY-MM-DD'));
            }
          }}
        />
        <Boton
          type="primary"
          className="ml-2"
          disabled={isButton}
          onClick={() => {
            handleDating();
          }}
          icon={<SearchOutlined />}
        />
        <Boton
          type="secondary"
          className="ml-2"
          disabled={isButton}
          onClick={() => {
            setFilterActive(false);
            setDataFilterMonth([]);
            message.success('Filtros eliminados con éxito');
          }}
          icon={<ClearOutlined />}
        />
      </div>

      <TablaSolicitudesCompra
        data={filterActive ? dataFilterMonth : dataRequests}
        loading={loading}
      />
    </>
  );
}

FilterRequest.propTypes = {
  handleDating: PropTypes.func,
  isButton: PropTypes.bool,
  setFilterActive: PropTypes.func,
  setDataFilterMonth: PropTypes.func,
  filterActive: PropTypes.bool,
  dataFilterMonth: PropTypes.array,
  dataRequests: PropTypes.array,
  loading: PropTypes.bool,
  startOfMonth: PropTypes.object,
  endOfMonth: PropTypes.object,
  setFechainial: PropTypes.func,
  setFechafinal: PropTypes.func
};
