import React, { useEffect, useState, useLayoutEffect } from 'react';
import TablaConceptos from '../Components/Organisms/TablaConceptos';
import { ArrowLeftOutlined, ReadOutlined, FileExcelOutlined } from '@ant-design/icons';
import clienteAxios from '../Utils/clienteAxios';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useNavigate } from 'react-router-dom';
import Boton from '../Components/Atoms/Boton';
import { Form, Input, Select } from 'antd';
import Modal from '../Components/Atoms/Modal';
import Swal from 'sweetalert2';

const HistorialConceptos = () => {
  const [historyCompanies, setHistoryCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [costCenters, setCostCenters] = useState([]);
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState('');
  const [selectedCostCenter, setSelectedCostCenter] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSpending, setSelectedSpending] = useState(null);
  const [selectedBuy, setSelectedBuy] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [realoadAddConcept, setRealoadAddConcept] = useState(false);

  let OptionBuy = [
    { value: 'SERVICIO', label: 'SERVICIO', key: 1 },
    { value: 'PRODUCTO', label: 'PRODUCTO', key: 2 }
  ];

  let OptionCategory = [
    { value: 'GASTO', label: 'GASTO', key: 1 },
    { value: 'COSTO', label: 'COSTO', key: 2 },
    { value: 'INVERSION', label: 'INVERSION', key: 3 }
  ];

  let OptionsSpending = [
    { value: 'VARIABLE', label: 'VARIABLE', key: 1 },
    { value: 'FIJO', label: 'FIJO', key: 2 }
  ];

  let OptionPayment = [
    { value: 'CAJA CHICA', label: 'CAJA CHICA', key: 1 },
    { value: 'AMERICAN EXPRESS', label: 'AMERICAN EXPRESS', key: 2 },
    { value: 'CAJA CHICA / AMERICAN EXPRESS', label: 'CAJA CHICA / AMERICAN EXPRESS', key: 3 }
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useLayoutEffect(() => {
    document.querySelector('.ant-table-content table').id = 'table-to-xls';
  }, []);

  useEffect(() => {
    clienteAxios
      .get(`/caja-chica/gastos/ver`)
      .then((response) => {
        let data = response.data.spents;
        data.reverse();
        console.log(data);
        setHistoryCompanies(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [loading]);

  useEffect(() => {
    clienteAxios
      .get(`/caja-chica/centros-de-costos/ver`)
      .then((response) => {
        let data = response.data.centers;
        const sortedCenter = data
          .map((item) => ({
            value: item.id,
            label: item.name,
            key: item.id
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setCostCenters(sortedCenter);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [loading]);

  const handleOk = () => {
    if (historyCompanies.some((company) => company.concept === companyName)) {
      Swal.fire({
        icon: 'error',
        title: 'El concepto ya existe!',
        showCancelButton: false,
        confirmButtonText: 'INTENTAR DE NUEVO'
      });
      return;
    }
    setIsSubmitting(true);
    clienteAxios
      .post(`/caja-chica/gastos/crear`, {
        concept: companyName,
        center_id: selectedCostCenter,
        outgo_type: selectedSpending,
        expense_type: selectedBuy,
        product_type: selectedCategory,
        payment_type: selectedPayment
      })
      .then(() => {
        setIsSubmitting(false);
        handleCancel();
        setRealoadAddConcept(!realoadAddConcept);
        Swal.fire({
          icon: 'success',
          title: 'Concepto agregado correctamente!',
          showCancelButton: false,
          confirmButtonText: 'LISTO'
        }).then(() => {
          setLoading(true);
        });
      })
      .catch((error) => {
        console.error(error);
        setIsSubmitting(false);
        Swal.fire({
          icon: 'error',
          title: 'Error al agregar el concepto!',
          showCancelButton: false,
          confirmButtonText: 'INTENTAR DE NUEVO'
        });
      });
  };

  return (
    <>
      <div className="title-content-between">
        <h1 className="mb-2 mr-5">
          <ArrowLeftOutlined className="arrow-size" onClick={() => navigate(-1)} />
          CONCEPTOS
        </h1>
        <Boton className="mb-3" type="secondary" icon={<ReadOutlined />} onClick={showModal}>
          Agregar Concepto
        </Boton>
        <div>
          <ReactHtmlTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="table-to-xls"
            filename="Reporte General"
            sheet="Reporte General"
            buttonText={
              <>
                <FileExcelOutlined className="mr-2" />
                Exportar en Excel
              </>
            }
          />
        </div>
      </div>

      <div className="flex-row flex-wrap mt-1">
        <TablaConceptos data={historyCompanies} setLoading={setLoading} loading={loading} />
      </div>

      <Modal
        title="AGREGAR CONCEPTO"
        centered
        open={isModalOpen}
        key={realoadAddConcept}
        onCancel={handleCancel}
        onOk={handleOk}
        confirmLoading={isSubmitting}>
        <Form
          className="mt-4"
          labelCol={{
            span: 10
          }}
          labelAlign="left"
          wrapperCol={{
            span: 14
          }}>
          <Form.Item
            rules={[{ required: true, message: 'Por favor ingrese el nombre de la empresa' }]}
            label="Nombre del Concepto">
            <Input
              type="text"
              placeholder="Nombre del concepto"
              onChange={(e) => setCompanyName(e.target.value)}
              onInput={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
            />
          </Form.Item>
          <Form.Item label="Centro de costos">
            <Select
              options={costCenters}
              placeholder="Seleccione una opción"
              showSearch
              filterOption={(inputValue, option) =>
                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
              }
              onChange={(value) => setSelectedCostCenter(value)}
            />
          </Form.Item>
          <Form.Item label="Categoría">
            <Select
              options={OptionsSpending}
              placeholder="Seleccione una opción"
              onChange={(value) => setSelectedSpending(value)}
            />
          </Form.Item>
          <Form.Item label="Tipo de adquisición">
            <Select
              options={OptionBuy}
              placeholder="Seleccione una opción"
              onChange={(value) => setSelectedCategory(value)}
            />
          </Form.Item>
          <Form.Item label="Tipo de gasto">
            <Select
              options={OptionCategory}
              placeholder="Seleccione una opción"
              onChange={(value) => setSelectedBuy(value)}
            />
          </Form.Item>
          <Form.Item label="Método de pago">
            <Select
              options={OptionPayment}
              placeholder="Seleccione una opción"
              onChange={(value) => setSelectedPayment(value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default HistorialConceptos;
