import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import './Assets/Styles/Global.css';

import Login from './Pages/Login';
import CrearInspeccionCalidad from './Pages/CrearInspeccionCalidad';
import RutaPrivada from './Middleware/RutaPrivada';
import AppLayout from './Components/Organisms/AppLayout';
import CrearIncidencia from './Pages/CrearIncidencia';
import Notifications from './Pages/Notifications';
import ConsultarIncidencia from './Pages/ConsultarIncidencia';
import ConsultarRemisiones from './Pages/ConsultarRemisiones';
import ConsultarInspeccionCalidad from './Pages/ConsultarInspeccionCalidad';
import DetallePedido from './Pages/DetallePedido';
import Pedidos from './Pages/Pedidos';
import Users from './Pages/Users';
import NotFound from './Pages/NotFound';
import Capacitacion from './Pages/Capacitacion';
import SolicitudesGasto from './Pages/SolicitudesGasto';
import NuevaSolicitud from './Pages/NuevaSolicitud';
import ReporteGeneral from './Pages/ReporteGeneral';
import DetalleGasto from './Pages/DetalleGasto';
import HistorialEmpresasEventuales from './Pages/HistorialEmpresasEventuales';
import HistorialIngresado from './Pages/HistorialIngresado';
import HistorialGastado from './Pages/HistorialGastado';
import HistorialEgresoEfectivo from './Pages/HistorialEgresoEfectivo';
import HistorialConceptos from './Pages/HistorialConceptos';
import Inicio from './Pages/Inicio';
import RoutesCompras from './Pages/RoutesCompras';
import CrearIncidenciasCalidad from './Pages/CrearIncidenciasCalidad';
import HistorialGastadoAmex from './Pages/HistorialGastadoAmex';

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/inicio" replace />} />

        <Route path="/" element={<Inicio />} />

        <Route path="/login" element={<Login />} />
        <Route element={<AppLayout />}>
          <Route exact path="/inicio" element={<RutaPrivada />}>
            <Route path="/inicio" element={<Inicio />} />
          </Route>
          <Route exact path="/pedidos" element={<RutaPrivada />}>
            <Route path="/pedidos" element={<Pedidos />} />
          </Route>
          <Route exact path="/pedidos/:id" element={<RutaPrivada />}>
            <Route path="/pedidos/:id" element={<DetallePedido />} />
          </Route>
          <Route exact path="/pedido/:id" element={<RutaPrivada />}>
            <Route path="/pedido/:id" element={<DetallePedido />} />
          </Route>
          <Route exact path="/pedidos/:idPedido/crear-incidencia" element={<RutaPrivada />}>
            <Route path="/pedidos/:idPedido/crear-incidencia" element={<CrearIncidencia />} />
          </Route>

          <Route exact path="/pedidos/:idPedido/crear-incidencia/ventas" element={<RutaPrivada />}>
            <Route
              exact
              path="/pedidos/:idPedido/crear-incidencia/ventas"
              element={<CrearIncidencia />}
            />
          </Route>

          <Route exact path="/pedidos/:idPedido/crear-incidencia/calidad" element={<RutaPrivada />}>
            <Route
              exact
              path="/pedidos/:idPedido/crear-incidencia/calidad"
              element={<CrearIncidenciasCalidad />}
            />
          </Route>

          <Route exact path="/rutas-de-entrega/" element={<RutaPrivada />}>
            <Route path="/rutas-de-entrega/" element={<RoutesCompras />} />
          </Route>

          <Route exact path="/consultar-incidencia/:idIncidencia" element={<RutaPrivada />}>
            <Route path="/consultar-incidencia/:idIncidencia" element={<ConsultarIncidencia />} />
          </Route>
          <Route exact path="/users" element={<RutaPrivada />}>
            <Route path="/users" element={<Users />} />
          </Route>
          <Route
            exact
            path="/rutas-de-entrega/:idRutaDeEntrega/remision/:idRemision"
            element={<RutaPrivada />}>
            <Route
              exact
              path="/rutas-de-entrega/:idRutaDeEntrega/remision/:idRemision"
              element={<ConsultarRemisiones />}
            />
          </Route>
          <Route
            exact
            path="/pedidos/:idPedido/crear-inspeccion-de-calidad"
            element={<RutaPrivada />}>
            <Route
              exact
              path="/pedidos/:idPedido/crear-inspeccion-de-calidad"
              element={<CrearInspeccionCalidad />}
            />
          </Route>
          <Route
            exact
            path="/pedidos/:idPedido/crear-inspeccion-de-calidad/:idProducto"
            element={<RutaPrivada />}>
            <Route
              exact
              path="/pedidos/:idPedido/crear-inspeccion-de-calidad/:idProducto"
              element={<CrearInspeccionCalidad />}
            />
          </Route>
          <Route exact path="/consultar-inspeccion-calidad/:idInspeccion" element={<RutaPrivada />}>
            <Route
              path="/consultar-inspeccion-calidad/:idInspeccion"
              element={<ConsultarInspeccionCalidad />}
            />
          </Route>
          <Route exact path="/notifications" element={<RutaPrivada />}>
            <Route exact path="/notifications" element={<Notifications />} />
          </Route>
          <Route exact path="/solicitudes-gasto" element={<RutaPrivada />}>
            <Route path="/solicitudes-gasto" element={<SolicitudesGasto />} />
          </Route>
          <Route exact path="/nueva-solicitud" element={<RutaPrivada />}>
            <Route path="/nueva-solicitud" element={<NuevaSolicitud />} />
          </Route>
          <Route exact path="/reporte-general" element={<RutaPrivada />}>
            <Route path="/reporte-general" element={<ReporteGeneral />} />
          </Route>
          <Route exact path="/historial-gastado" element={<RutaPrivada />}>
            <Route path="/historial-gastado" element={<HistorialGastado />} />
          </Route>
          <Route exact path="/historial-gastado-amex" element={<RutaPrivada />}>
            <Route path="/historial-gastado-amex" element={<HistorialGastadoAmex />} />
          </Route>
          <Route exact path="/historial-ingresado" element={<RutaPrivada />}>
            <Route path="/historial-ingresado" element={<HistorialIngresado />} />
          </Route>
          <Route exact path="/historial-egreso-efectivo" element={<RutaPrivada />}>
            <Route path="/historial-egreso-efectivo" element={<HistorialEgresoEfectivo />} />
          </Route>
          <Route exact path="/historial-empresas" element={<RutaPrivada />}>
            <Route path="/historial-empresas" element={<HistorialEmpresasEventuales />} />
          </Route>
          <Route exact path="/historial-conceptos" element={<RutaPrivada />}>
            <Route path="/historial-conceptos" element={<HistorialConceptos />} />
          </Route>
          <Route exact path="/detalle-gasto/:id" element={<RutaPrivada />}>
            <Route path="/detalle-gasto/:id" element={<DetalleGasto />} />
          </Route>
          <Route exact path="/capacitacion" element={<RutaPrivada />}>
            <Route exact path="/capacitacion" element={<Capacitacion />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};
export default App;
