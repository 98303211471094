import React, { useEffect, useState, useLayoutEffect } from 'react';
import TablaHistorialGastadoAmex from '../Components/Organisms/TablaHistorialGastadoAmex';
import { ArrowLeftOutlined, FileExcelOutlined } from '@ant-design/icons';
import clienteAxios from '../Utils/clienteAxios';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'antd';
import decodeToken from '../Utils/decodeToken';

const HistorialGastadoAmex = () => {
  const [egressHistory, setEgressHistory] = useState([]);
  const [egressHistoryPL, setEgressHistoryPL] = useState([]);
  const [egressHistoryBH, setEgressHistoryBH] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  let decodedToken = decodeToken(localStorage.getItem('token'));
  const userRole = decodedToken?.role?.name;

  useLayoutEffect(() => {
    document.querySelector('.ant-table-content table').id = 'table-to-xls';
  }, []);

  useEffect(() => {
    clienteAxios
      .get(`/caja-chica/history/spent/amex`)
      .then((response) => {
        let data = response.data;
        console.log(data);
        // data.reverse();
        setEgressHistory(data?.datosAmex);
        setEgressHistoryPL(data?.datosAmexPL);
        setEgressHistoryBH(data?.datosAmexBH);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <div className="title-content-between">
        <h1 className="mb-2 mr-5">
          <ArrowLeftOutlined className="arrow-size" onClick={() => navigate(-1)} />
          HISTORIAL DE PRESUPUESTO GASTADO AMEX
        </h1>
        <div className="mb-2 flex justify-content-end">
          <ReactHtmlTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="table-to-xls"
            filename="Historial Gastado"
            sheet="Historial Gastado"
            buttonText={
              <>
                <FileExcelOutlined className="mr-2" />
                Exportar en Excel
              </>
            }
          />
        </div>
      </div>

      <div className="mt-1">
        <Alert
          className="width-100"
          message="En este apartado solo se muestra las solicitudes de presupuesto que se encuentran en estado de 'Recibido'."
          type="info"
          showIcon
          closable
        />
      </div>

      <div className="flex-row flex-wrap mt-1">
        <TablaHistorialGastadoAmex
          data={
            userRole === 'administrator' || userRole === 'Super_Admin'
              ? egressHistory
              : userRole === 'adquisiciones_pl'
              ? egressHistoryPL
              : userRole === 'adquisiciones_bh'
              ? egressHistoryBH
              : null
          }
          loading={loading}
        />
      </div>
    </>
  );
};

export default HistorialGastadoAmex;
