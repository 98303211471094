import { Form, Alert, Select, Tag, Table, Tooltip, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import currencyFormatter from '../../Utils/currencyFormatter';

export default function FormDetalleGasto({
  expenseDetail,
  handleUpdateCompany,
  handleEventualWeekend,
  handleUpdatePay,
  showModalAmount,
  showModalExcess,
  openFileExcess,
  openFileSurplus,
  openFileMissing,
  payments,
  payment,
  changePayment,
  handlePostChangePayment,
  isNormal,
  isShopping,
  isAdquisionBH,
  isAdquisionPL,
  isCancel,
  isPurchaseOrder,
  isReceived,
  isDevolution,
  isDevolutionReturn,
  decodedToken,
  isUser,
  columns,
  navigate,
  isSuperAdmin
}) {
  return (
    <>
      <Form
        labelCol={{
          span: 7
        }}
        labelAlign="left"
        wrapperCol={{
          span: 10
        }}
        layout="horizontal">
        <h1 className="mb-4 mr-10">
          <ArrowLeftOutlined className="arrow-size" onClick={() => navigate(-1)} /> Detalle de la
          Solicitud
        </h1>

        {/* // Tags informativas */}
        <div className="mb-4">
          {expenseDetail?.approved_status !== 'rechazada' &&
          isUser &&
          expenseDetail?.purchase_table_name === 'pedido-en-proceso' ? (
            <Alert
              message="Una vez generada la 'SOLICITUD DE GASTO' ya no puedes hacer ningún cambio, en caso de alguna equivocación pide a tú 'JEFE DIRECTO' que deniegue la solicitud y genera una nueva."
              type="warning"
              showIcon
              closable
            />
          ) : (isShopping || isAdquisionPL || isAdquisionBH) &&
            isCancel &&
            expenseDetail?.payment_method === 'SIN MÉTODO DE PAGO' &&
            isPurchaseOrder ? (
            <Alert
              message="Una vez hayas actualizado el método de pago, podrás confirmar la compra."
              type="info"
              showIcon
              closable
            />
          ) : decodedToken?.role?.name !== 'caja_chica' &&
            isCancel &&
            expenseDetail?.type_status !== 'devolución' &&
            expenseDetail?.purchase_table_name === 'pedido-entregado' ? (
            <Alert
              message="Rectifica que tú producto este en buen estado o que el servicio se cumplio correctamente, 
                si no es asi pide al comprador que deseas realizar la devolución."
              type="info"
              showIcon
              closable
            />
          ) : (isShopping || isAdquisionPL || isAdquisionBH) &&
            isUser &&
            isCancel &&
            expenseDetail?.purchase_table_name === 'pedido-entregado' ? (
            <Alert
              message="Rectifica que el producto este en buen estado o que el servicio se cumplio correctamente, 
                si es así, presentate con la persona que realizo la 'SOLICITUD DE GASTO' para ver si cumple con sus condiciones,
                de lo contrario realiza la devolución."
              type="info"
              showIcon
              closable
            />
          ) : null}
        </div>

        {/* // Datos de la solicitud */}
        <Form.Item label={<b>Nombre Completo</b>}>
          <span className="ant-form-text">{expenseDetail?.user_name}</span>
        </Form.Item>
        <Form.Item label={<b>Empresa</b>}>
          <span className="ant-form-text">
            {expenseDetail.company && expenseDetail.company[0]?.company_name}
          </span>
        </Form.Item>

        <Form.Item label={<b>Centro de costos</b>}>
          <span className="ant-form-text">
            {expenseDetail.center && expenseDetail?.center[0].center_name}
          </span>
        </Form.Item>
        <Form.Item label={<b>Concepto</b>}>
          <span
            className="ant-form-text"
            style={{ width: '80%', overflowWrap: 'anywhere', textAlign: 'justify' }}>
            {expenseDetail.spent && expenseDetail?.spent[0].spent_name}
          </span>
        </Form.Item>
        {expenseDetail.event && expenseDetail.event.length > 0 ? (
          <>
            <Form.Item label={<b>Eventuales</b>}>
              {expenseDetail?.spent[0].spent_name !== 'EVENTUALES' ? (
                <Tooltip title="Haz clic aquí si la empresa de algún eventual es incorrecta o 'Sin definir'">
                  <Typography.Link className="ml-2" onClick={handleUpdateCompany}>
                    Actualizar empresa eventuales
                  </Typography.Link>
                </Tooltip>
              ) : null}
            </Form.Item>
            <Form.Item>
              <Table
                columns={columns}
                dataSource={expenseDetail.event.map((event) => ({
                  key: uuidv4(),
                  name: event.name,
                  pay: event.pay,
                  company_name: event.company_name
                }))}
                pagination={{
                  position: ['bottomCenter'],
                  defaultPageSize: 3
                }}
              />
            </Form.Item>
          </>
        ) : null}
        {expenseDetail.spent &&
        expenseDetail.spent[0].spent_name === 'EVENTUALES FIN DE SEMANA' &&
        expenseDetail.event.length === 0 ? (
          <>
            <Form.Item label={<b>Eventuales</b>}>
              <span className="ant-form-text">SIN DEFINIR</span>
              <Tooltip title="Haz clic aquí si no registraste eventuales">
                <Typography.Link className="ml-2" onClick={handleEventualWeekend}>
                  Registrar Eventuales
                </Typography.Link>
              </Tooltip>
            </Form.Item>
          </>
        ) : null}

        <Form.Item label={<b>Estado</b>}>
          {expenseDetail && (
            <>
              {isNormal && expenseDetail.approved_status === 'pendiente' && (
                <Tag color="geekblue">EN PROCESO</Tag>
              )}
              {expenseDetail.approved_status === 'en aprobación por administrador' && isNormal && (
                <Tag color="geekblue">EN PROCESO</Tag>
              )}
              {expenseDetail.approved_status === 'rechazada' && <Tag color="error">RECHAZADO</Tag>}
              {isNormal && expenseDetail.purchase_table_name === 'pedido-entregado' && (
                <Tag color="gold">POR ENTREGAR</Tag>
              )}
              {expenseDetail.approved_status === 'aprobada' &&
                isNormal &&
                expenseDetail.purchase_table_name === 'pedido-recibido' && (
                  <Tag color="success">RECIBIDO</Tag>
                )}
              {isDevolution && expenseDetail.type_status === 'en proceso' && isDevolutionReturn && (
                <Tag color="gold">DEVOLUCIÓN EN PROCESO</Tag>
              )}
              {isDevolution && expenseDetail.type_status === 'rechazada' && isDevolutionReturn && (
                <Tag color="red">DEVOLUCIÓN RECHAZADA</Tag>
              )}
              {isDevolution && isNormal && isDevolutionReturn && (
                <Tag color="magenta">DEVOLUCIÓN COMPLETA</Tag>
              )}
              {expenseDetail.purchase_table_name === 'pedido-en-compra' && isNormal && (
                <Tag color="purple">ADQUISICIÓN</Tag>
              )}
              {expenseDetail.purchase_table_name === 'pedido-en-compra' &&
                expenseDetail.type_status === 'cancelado' && <Tag color="error">RECHAZADO</Tag>}
            </>
          )}
        </Form.Item>

        {(isShopping || isAdquisionPL || isAdquisionBH || isSuperAdmin) &&
        expenseDetail?.type_status === 'normal' &&
        isPurchaseOrder ? (
          <Form.Item label={<b>Método de pago</b>}>
            {expenseDetail?.payment_method === 'SIN MÉTODO DE PAGO' ? (
              <>
                <Select
                  style={{
                    width: '240px'
                  }}
                  placeholder="Selecciona el método"
                  options={
                    isSuperAdmin
                      ? payments
                      : isAdquisionPL || isAdquisionBH
                      ? payments.filter((payment) => payment.value !== 1)
                      : isShopping
                      ? payments.filter((payment) => payment.value !== 2)
                      : null
                  }
                  required
                  onChange={changePayment}
                  mode="single"
                  value={payment}
                />
                <Tooltip title="Haz clic para actualizar el método de pago">
                  <Typography.Link className="ml-2" onClick={handlePostChangePayment}>
                    Actualizar método de pago
                  </Typography.Link>
                </Tooltip>
              </>
            ) : (
              <span className="ant-form-text">{expenseDetail?.payment_method}</span>
            )}
          </Form.Item>
        ) : (
          <Form.Item label={<b>Método de pago</b>}>
            <span className="ant-form-text">{expenseDetail?.payment_method}</span>
          </Form.Item>
        )}

        <Form.Item label={<b>Descripción de la necesidad</b>}>
          <span
            className="ant-form-text"
            style={{ overflowWrap: 'anywhere', textAlign: 'justify' }}>
            {expenseDetail?.description}
          </span>
        </Form.Item>
        <Form.Item label={<b>Importe</b>}>
          <>
            <span className="ant-form-text">{currencyFormatter(expenseDetail?.total)}</span>
            {expenseDetail?.payment_method !== 'SIN MÉTODO DE PAGO' &&
            expenseDetail.spent &&
            (isShopping || isAdquisionPL || isAdquisionBH || isSuperAdmin) &&
            expenseDetail?.purchase_table_name !== 'pedido-en-proceso' &&
            (expenseDetail?.spent[0].spent_name === 'EVENTUALES MAQUILA' ||
              expenseDetail?.spent[0].spent_name === 'EVENTUALES') ? (
              <>
                <Tooltip title="Haz clic aquí si el importe de algún eventual es incorrecto">
                  <Typography.Link className="ml-2" onClick={handleUpdatePay}>
                    Actualizar pago eventuales
                  </Typography.Link>
                </Tooltip>
              </>
            ) : (isShopping || isAdquisionPL || isAdquisionBH || isSuperAdmin) &&
              expenseDetail?.payment_method !== 'SIN MÉTODO DE PAGO' &&
              expenseDetail?.spent?.[0]?.spent_name !== 'EVENTUALES FIN DE SEMANA' &&
              isCancel &&
              isPurchaseOrder ? (
              <Tooltip title="Actualiza el importe de pago en caso de que el precio haya cambiado al momento de la compra">
                <Typography.Link className="ml-2" onClick={showModalAmount}>
                  Actualizar pago
                </Typography.Link>
              </Tooltip>
            ) : isReceived &&
              isUser &&
              expenseDetail?.returnmoney.length === 0 &&
              expenseDetail?.payment_method === 'EFECTIVO' &&
              expenseDetail?.spent[0].spent_name !== 'EVENTUALES' &&
              expenseDetail?.spent[0].spent_name !== 'EVENTUALES FIN DE SEMANA' &&
              expenseDetail?.spent[0].spent_name !== 'EVENTUALES MAQULA' ? (
              <Tooltip title="Haz clic aquí si posees algún excedente de efectivo">
                <Typography.Link className="ml-2" onClick={showModalExcess}>
                  ¿Posees algún excedente de efectivo?
                </Typography.Link>
              </Tooltip>
            ) : null}
          </>
        </Form.Item>

        {/* CAMPO PARA MOSTRAR LA FIRMA DE DEVOLCUION DE EXCEDENE */}
        {!Array.isArray(expenseDetail?.returnmoney) ? (
          <Form.Item className="text-align-jusify" label={<b>Devolución de excedente</b>}>
            <span className="ant-form-text">
              <strong>{expenseDetail?.returnmoney?.return_user_id}</strong> al ingresar la
              solicitud, el importe era de{' '}
              <strong>{`$${expenseDetail?.returnmoney?.previous_total}`}</strong> pero devolvió la
              suma de{' '}
              <strong>
                {expenseDetail?.returnmoney?.total_return &&
                  `$${expenseDetail?.returnmoney?.total_return}`}
              </strong>{' '}
              con el motivo de <strong>{expenseDetail?.returnmoney?.description}</strong> con fecha
              y hora de <strong>{expenseDetail?.returnmoney?.created_at}</strong>{' '}
              {expenseDetail?.returnmoney?.file_exchange_returns && (
                <>
                  <span> junto al </span>
                  <a onClick={openFileExcess}>Comprobante</a>{' '}
                </>
              )}
              y el estado actual de recibido de exceso de fondo es{' '}
              <Tag
                color={
                  expenseDetail?.returnmoney?.status === 'Sin confirmar' ? 'error' : 'success'
                }>
                {expenseDetail?.returnmoney?.status}
              </Tag>
              {expenseDetail?.returnmoney?.status === 'Confirmado' && (
                <>
                  por la persona <strong>{expenseDetail?.returnmoney?.confirmation_user_id}</strong>{' '}
                  con fecha y hora de{' '}
                  <strong>{expenseDetail?.returnmoney?.confirmation_datetime}</strong>
                </>
              )}
            </span>
          </Form.Item>
        ) : null}

        {/* CAMPO PARA MOSTRAR LA FIRMA DE ENTREGA DE SOBRANTE */}
        {!Array.isArray(expenseDetail?.lessmoneyeventual) ? (
          <Form.Item style={{ textAlign: 'justify' }} label={<b>Entrega de sobrante</b>}>
            <span className="ant-form-text">
              <strong>{expenseDetail?.lessmoneyeventual?.id_applicant_person}</strong> al ingresar
              la solicitud el importe, era de{' '}
              <strong>${expenseDetail?.lessmoneyeventual?.previous_total}</strong> pero al registrar
              los eventuales con fecha y hora de{' '}
              <strong>{expenseDetail?.lessmoneyeventual?.created_at}</strong> por el motivo de{' '}
              <strong>{expenseDetail?.lessmoneyeventual?.description}</strong> , el monto se
              actualizo a <strong>${expenseDetail?.lessmoneyeventual?.current_total}</strong>, se
              adjunta el <a onClick={openFileSurplus}>Comprobante</a> y el estado de entrega de
              faltante es{' '}
              <Tag
                color={
                  expenseDetail?.lessmoneyeventual?.status === 'Sin confirmar' ? 'error' : 'success'
                }>
                {expenseDetail?.lessmoneyeventual?.status}
              </Tag>
              {expenseDetail?.lessmoneyeventual?.status === 'Confirmado' && (
                <>
                  por la persona{' '}
                  <strong>{expenseDetail?.lessmoneyeventual?.id_person_who_delivers}</strong> con
                  fecha y hora de{' '}
                  <strong>{expenseDetail?.lessmoneyeventual?.confirmation_datetime}</strong>
                </>
              )}
            </span>
          </Form.Item>
        ) : null}

        {/* CAMPO PARA MOSTRAR LA ENTREGA DE FALTANTE */}
        {!Array.isArray(expenseDetail?.moremoneyeventual) ? (
          <Form.Item className="text-align-jusify" label={<b>Entrega de faltante</b>}>
            <span className="ant-form-text">
              <strong>{expenseDetail?.moremoneyeventual?.id_applicant_person}</strong> al ingresar
              la solicitud el importe, era de{' '}
              <strong>${expenseDetail?.moremoneyeventual?.previous_total}</strong> pero al registrar
              los eventuales con fecha y hora de{' '}
              <strong>{expenseDetail?.moremoneyeventual?.created_at}</strong> por el motivo de{' '}
              <strong>{expenseDetail?.moremoneyeventual?.description}</strong> , el monto se
              actualizo a <strong>${expenseDetail?.moremoneyeventual?.current_total}</strong>, se
              adjunta el <a onClick={openFileMissing}>Comprobante</a> y el estado de entrega de
              faltante es{' '}
              <Tag
                color={
                  expenseDetail?.moremoneyeventual?.status === 'Sin confirmar' ? 'error' : 'success'
                }>
                {expenseDetail?.moremoneyeventual?.status}
              </Tag>
              {expenseDetail?.moremoneyeventual?.status === 'Confirmado' && (
                <>
                  por la persona{' '}
                  <strong>{expenseDetail?.moremoneyeventual?.id_person_who_delivers}</strong> con
                  fecha y hora de{' '}
                  <strong>{expenseDetail?.moremoneyeventual?.confirmation_datetime}</strong>
                </>
              )}
            </span>
          </Form.Item>
        ) : null}
      </Form>
    </>
  );
}

FormDetalleGasto.propTypes = {
  expenseDetail: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  handleUpdateCompany: PropTypes.func,
  handleEventualWeekend: PropTypes.func,
  handleUpdatePay: PropTypes.func,
  showModalAmount: PropTypes.func,
  showModalExcess: PropTypes.func,
  openFileExcess: PropTypes.func,
  openFileSurplus: PropTypes.func,
  openFileMissing: PropTypes.func,
  payments: PropTypes.array,
  payment: PropTypes.number,
  changePayment: PropTypes.func,
  handlePostChangePayment: PropTypes.func,
  isNormal: PropTypes.bool,
  isShopping: PropTypes.bool,
  isCancel: PropTypes.bool,
  isPurchaseOrder: PropTypes.bool,
  isReceived: PropTypes.bool,
  isDevolution: PropTypes.bool,
  isDevolutionReturn: PropTypes.bool,
  isAdmin: PropTypes.bool,
  decodedToken: PropTypes.object,
  selectedDate: PropTypes.object,
  isUser: PropTypes.bool,
  columns: PropTypes.array,
  navigate: PropTypes.func,
  isSuperAdmin: PropTypes.bool,
  isAdquisionBH: PropTypes.bool,
  isAdquisionPL: PropTypes.bool
};
