import { Input, Space, Table } from 'antd';
import React, { useState, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import localeObjectTable from '../../Utils/localeObjectTable';
import PropTypes from 'prop-types';
import currencyFormatter from '../../Utils/currencyFormatter';
import Boton from '../Atoms/Boton';

const TablaHistorialGastadoAmex = ({ data, loading }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: '0 8px 8px 8px'
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Boton
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
          className="button-close-search-filter-table">
          X
        </Boton>
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Boton
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small">
            Buscar
          </Boton>
          <Boton onClick={() => clearFilters && handleReset(clearFilters)} size="small" danger>
            Limpiar
          </Boton>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  const tablaReporte = [
    {
      title: 'ID SOLICITUD',
      dataIndex: 'id_purchase',
      key: 'id_purchase',
      ...getColumnSearchProps('id_purchase'),
      sorter: (a, b) => a.id_purchase - b.id_purchase,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'CONFRIMADO POR',
      dataIndex: 'id_user',
      key: 'id_user',
      ...getColumnSearchProps('id_user'),
      sorter: (a, b) => a.id_user.localeCompare(b.id_user),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'EMPRESA',
      dataIndex: 'company',
      key: 'company',
      ...getColumnSearchProps('company'),
      sorter: (a, b) => a.company.localeCompare(b.company),
      sortDirections: ['ascend', 'descend'],
      ellipsis: true
    },
    {
      title: 'TOTAL',
      dataIndex: 'total',
      key: 'total',
      ...getColumnSearchProps('total'),
      sorter: (a, b) => a.total - b.total,
      sortDirections: ['ascend', 'descend'],
      render: (text) => currencyFormatter(text)
    }
  ];

  const newDataSOL = data?.map((item, index) => {
    return {
      key: index,
      id_purchase: item?.id_purchase,
      id_user: item?.id_user,
      created_at: item?.created_at,
      total: item.total,
      company: item?.company
    };
  });
  return (
    <>
      <Table
        loading={loading}
        columns={tablaReporte}
        dataSource={newDataSOL}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 10,
          pageSizeOptions: [20, 40, 60, 80, 100],
          total: data.total,
          showTotal: (total, range) =>
            `Mostrando gastos del ${range[0]} al ${range[1]} de un total de ${total}`
        }}
        className="mt-4 mb-4"
        locale={localeObjectTable}
        scroll={{
          x: true
        }}
      />
    </>
  );
};

TablaHistorialGastadoAmex.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool
};
export default TablaHistorialGastadoAmex;
